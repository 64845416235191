import React, { useContext, useEffect, useRef, useState } from "react";
import "./App.css";

const ROOT = "https://slides.die-buecherheimat.de";
const TRANSITION_TIME = 500;

interface Config {
  slideshow: { data: Slideshow };
  puzzle: { data: Puzzle } | undefined;
}

interface Puzzle {
  id: string;
  attributes: {
    Image: MediaObject;
    PuzzleImage: MediaObject;
    updatedAt: string;
  };
}

interface Slide {
  Type: "Picture" | "ArchivePicture" | "Custom";
  Title: string;
  TimeInSeconds: number;
  ArchivePictureId: string | null;
  ArchiveInfo: { url: string; description: string; title: string } | null;
  CustomType: "puzzle" | null;
  File: MediaObject;
  id: number;
}

interface MediaObject {
  data: {
    attributes: {
      url: string;
      alternativeText: string;
    };
  };
}

interface Slideshow {
  attributes: {
    Slides: Slide[];
    Title: string;
    updatedAt: string;
  };
  id: string;
}

const PuzzleContext = React.createContext<
  [string, React.Dispatch<React.SetStateAction<string>>]
>(["", () => {}]);

function App() {
  const [slideshow, setSlideShow] = useState<Slideshow | null>(null);
  const [puzzle, setPuzzle] = useState<Puzzle | null>(null);
  const slideshowRef = useRef<Slideshow | null>(null);
  const puzzleRef = useRef<Puzzle | null>(null);
  const [error, setError] = useState("");
  const [puzzleUpdateStamp, setPuzzleUpdateStamp] = useState("");

  useEffect(() => {
    const load = async () => {
      const previewId = new URL(window.location.href).searchParams?.get("id");
      const config: Config | null = previewId
        ? {
            slideshow: {
              data: (
                await fetch(
                  `${ROOT}/api/slideshows/${previewId}?populate[0]=Slides&populate[1]=Slides.File`
                ).then((r) => r.json())
              )?.data,
            },
          }
        : (
            await fetch(
              `${ROOT}/api/bucherheimat?populate[0]=slideshow&populate[1]=slideshow.Slides&populate[2]=slideshow.Slides.File&populate[3]=puzzle&populate[4]=puzzle.PuzzleImage`
            ).then((r) => r.json())
          )?.data?.attributes;

      if (!config) {
        setError(`Error loading slideshow`);
        return;
      }

      const slideshow = config.slideshow.data;

      if (
        config.puzzle?.data &&
        (!puzzleRef.current ||
          puzzleRef.current.id !== config.puzzle.data.id ||
          puzzleRef.current.attributes.updatedAt !==
            config.puzzle.data.attributes.updatedAt)
      ) {
        console.log(config.puzzle.data.attributes.updatedAt);
        puzzleRef.current = config.puzzle.data;
        setPuzzleUpdateStamp(config.puzzle.data.attributes.updatedAt);
        setPuzzle(config.puzzle.data);
      }

      if (
        !slideshowRef.current ||
        slideshowRef.current.id !== slideshow.id ||
        slideshowRef.current.attributes.updatedAt !==
          slideshow.attributes.updatedAt
      ) {
        await Promise.all(
          slideshow.attributes.Slides.filter(
            (slide) => slide.Type === "ArchivePicture" && slide.ArchivePictureId
          ).map(async (slide) => {
            const id = slide.ArchivePictureId!.match(/\d+$/)![0];
            const json: {
              data: {
                attributes: {
                  media: MediaObject;
                  descriptions: { data: { attributes: { text: string } }[] };
                  title: { data: { attributes: { text: string } } };
                };
              };
            } = await fetch(
              `https://bp.bad-harzburg-stiftung.de/api/api/pictures/${id}?populate[0]=media&populate[1]=descriptions&populate[2]=title`
            ).then((r) => r.json());
            slide.ArchiveInfo = {
              url: json.data.attributes.media.data.attributes.url,
              title: json.data.attributes.title.data.attributes.text,
              description:
                json.data.attributes.descriptions.data[0].attributes.text,
            };
          })
        );

        slideshowRef.current = slideshow;
        setSlideShow(slideshow);
      }
    };

    const intervalId = setInterval(() => load(), 20000);
    load().catch(e => alert(e));

    return () => clearInterval(intervalId);
  }, []);

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement!.requestFullscreen();
    }
  };

  return (
    <PuzzleContext.Provider value={[puzzleUpdateStamp, setPuzzleUpdateStamp]}>
      <div className="slideshow" onClick={toggleFullscreen}>
        {slideshow ? (
          <SlideshowDisplay slideshow={slideshow} puzzle={puzzle} />
        ) : !error ? (
          <div>Bilder werden geladen...</div>
        ) : (
          <div>{error}</div>
        )}
      </div>
    </PuzzleContext.Provider>
  );
}

function SlideshowDisplay({
  slideshow,
  puzzle,
}: {
  slideshow: Slideshow;
  puzzle: Puzzle | null;
}) {
  const contentForSlide = (slide: Slide) => {
    switch (slide.Type) {
      case "ArchivePicture":
        return slide.ArchiveInfo?.url ? (
          <>
            <div className="archive-info">
              <div className="archive-title">{slide.ArchiveInfo.title}</div>
              <div className="archive-description">
                {slide.ArchiveInfo.description}
              </div>
            </div>
            <img
              alt={slide.ArchiveInfo.title}
              src={`https://bp.bad-harzburg-stiftung.de/${slide.ArchiveInfo.url}`}
            />
          </>
        ) : null;
      case "Picture":
        return (
          <img
            alt={slide.File.data.attributes.alternativeText}
            src={`${ROOT}${slide.File.data.attributes.url}`}
          />
        );
      case "Custom":
        return puzzle ? (
          <PuzzleView url={puzzle.attributes.PuzzleImage.data.attributes.url} />
        ) : (
          <div>no puzzle set</div>
        );
      default:
        return <span>Unknown type</span>;
    }
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const maxSlides = slideshow.attributes.Slides.length;
  const slide = slideshow.attributes.Slides[activeIndex];
  const nextSlide = slideshow.attributes.Slides[(activeIndex + 1) % maxSlides];

  useEffect(() => {
    if (slide) {
      const timeoutId = setTimeout(async () => {
        setActiveIndex((previousIndex) => {
          return (previousIndex + 1) % maxSlides;
        });
      }, slide.TimeInSeconds * 1000 - TRANSITION_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [slide, maxSlides]);

  // dirty animation code
  return maxSlides > 0 ? (
    <div className="slick-track transitioning" key={activeIndex}>
      <div className="slide">{contentForSlide(slide)}</div>
      <div className="slide next">{contentForSlide(nextSlide)}</div>
    </div>
  ) : (
    <span>no slide</span>
  );
}

function PuzzleView({ url }: { url: string }) {
  const [puzzleStamp] = useContext(PuzzleContext);
  return <img src={`${ROOT}${url}?stamp=${puzzleStamp}`} alt="puzzle" />;
}

export default App;
